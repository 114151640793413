<template>
  <div>
    <tableComponent
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
      :enable-row-clickable="true"
      @newDocument="newDocument"
      @clickedRow="openStorageLocation($event)"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../components/api/api";
import tableComponent from "../../components/_utils/dataTableHelpers/tableComponent.vue";

export default {
  components: {
    tableComponent,
  },
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [
        {
          title: "dangerousGoods.storageLocation.new",
          color: "secondary",
          action: "newDocument",
          icon: "add",
          iconDark: true,
          dark: true,
          outlined: true,
        },
      ],
      multipleSelectButtons: [],
      isRowClickable: true,
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.storageLocationAction",
          componentName: "storageLocationAction",
        },
      ],
      itemKey: "id",
      uriPartPrepend: "dangerousGoodStorageLocation",
      uriPartAppend: "",
      populate: [],
      sort: "-createdAt",
      editFormPushName: "dangerousGoodsStorageLocationForm",
      detailViewPushName: "dangerousGoodsList",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("dangerousGoods.storageLocation.tableHeader.name"),
          value: "name",
          align: "left",
        },
        {
          text: "",
          value: "storageLocationAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    reloadDataFunc() {
      this.$refs.tableComponent.getData();
    },
    newDocument() {
      this.$router.push({
        name: this.tableData.editFormPushName,
        params: { id: "1", mode: "new", data: 1 },
      });
    },
    openStorageLocation(event) {
      this.$router.push({
        name: this.tableData.detailViewPushName,
        params: {
          storageLocationId: event.id,
          storageLocationName: event.name,
        },
      });
    },
  },
};
</script>

<template>
  <div>
    <div v-for="(area, i) in value.areas" v-bind:key="i">
      <v-list dense subheader>
        <v-subheader class="mt-4">{{ area.name }}</v-subheader>

        <v-list-item
          v-for="(task, e) in area.tasks"
          v-bind:key="e"
          class="my-2"
        >
          <v-list-item-content>
            <v-list-item-title>{{ task.name }}</v-list-item-title>
            <!--v-list-item-subtitle>{{ area }}</v-list-item-subtitle-->
            <v-textarea
              class="mb-2 mt-2"
              data-cy="content"
              v-model="task.description"
              dense
              hide-details
              outlined
              label="Kommentar"
              rows="1"
            ></v-textarea>
            <v-btn
              v-if="task.ticket == null"
              color="primary"
              class="mr-4 mb-3"
              small
              outlined
              @click="createTicket(task, i, e)"
              >{{ $t("inspections.createTicket") }}  <v-icon class="ml-1">launch</v-icon></v-btn
            >
          </v-list-item-content>
          <v-list-item-icon>
            <v-radio-group v-model="task.ok" row class="mt-0 pt-0">
              <v-radio color="success" :value="true">
                <template v-slot:label>
                  <div style="color: #4caf50">i.O.</div>
                </template>
              </v-radio>
              <v-radio color="#ff5252" :value="false">
                <template v-slot:label>
                  <div style="color: #ff5252">n.i.O.</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <v-divider class="mt-6" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["value"],
  components: {},
  data: () => ({}),
  methods: {
    createTicket(task, areaId, taskId) {
      //get standard board
      this.$store
        .dispatch("getSettingData", "standardOpenBoard")
        .then((item) => {
          let ticketData = {
            recurring: 0,
            title: task.name,
            description: task.description,
            dueDate: null,
            users: [],
            priority: 1,
            board: item.value,
          };
          //create ticket
          this.$store.dispatch("addTicket", ticketData).then((r) => {
            //set ticket id
            this.value.areas[areaId].tasks[taskId].ticket = r._id;
          });
        });
    },
  },
};
</script>

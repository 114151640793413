<template>
  <div>
    <div class="subtitle-1">
      {{ $t("dangerousGoods.dangerousGoodsList.form.safetyDataSheet") }}
    </div>
    <input
      @change="handleImage"
      class="mt-2"
      type="file"
      accept="application/pdf"
    />
  </div>
</template>
<script>
export default {
  props: ["value"],
  methods: {
    handleImage(e) {
      const selectedFile = e.target.files[0]; // get first file
      this.createBase64Image(selectedFile);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.value.safetyDataSheet.filetype = fileObject.name
          .match(/\.[0-9a-z]+$/i)[0]
          .split(".")[1];
        this.value.safetyDataSheet.name = fileObject.name;
        this.value.safetyDataSheet.content = e.target.result.split(",")[1];
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>

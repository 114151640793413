<template>
  <v-select
    v-if="inputVal.recurring == 0 || inputVal.recurring"
    :items="items"
    item-text="name"
    item-value="value"
    :label="$t('tickets.recurringTicket')"
    data-cy="recurring"
    v-model="inputVal.recurring"
    dense
  ></v-select>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      items: [
        { name: "einmalige Aufgabe", value: 0 },
        { name: "wöchentlich", value: 1 },
        { name: "monatlich", value: 2 },
        { name: "halbjährlich", value: 24 },
        { name: "zweijährlich", value: 48 },
        { name: "alle zwei Jahre", value: 96 },
        { name: "alle drei Jahre", value: 144 },
      ],
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

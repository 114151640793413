<template>
  <div>
    <v-row class="my-3">
      <v-icon class="mr-2" disabled>notes</v-icon>
      <div class="subtitle-1">{{ $t("tickets.view.description") }}</div></v-row
    >
    <div class="ml-5" v-html="item.description"></div>
    <v-divider class="my-4 ml-5"></v-divider>
    <attachmentSection ref="attachmentSection" @reloadData="$emit('reloadData')" :item="item" />
    <v-divider class="my-4 ml-5"></v-divider>
    <commentSection ref="commentSection" @reloadData="$emit('reloadData')" :item="item" />
     <v-divider class="my-4 ml-5 mt-8"></v-divider>
    <activitySection ref="activitySection" :item="item" />
  </div>
</template>
<script>
import commentSection from "./commentSection.vue";
import attachmentSection from "./attachmentSection.vue";
import activitySection from "./activitySection.vue";
export default {
  props: ["item"],
  components: { commentSection, attachmentSection, activitySection },
  data() {
    return {};
  },
  updated() {
    this.triggerLoadSub();
  },
  mounted() {
    this.triggerLoadSub();
  },
  methods: {
    triggerLoadSub() {
      this.$refs.commentSection.loadMessanges();
      this.$refs.attachmentSection.loadAttachments();
    },
  },
};
</script>

<template>
  <div>
    <v-row my-4 justify="space-around">
      <singleDateForm
        v-model="value.start"
        :label="$t('incidentReports.startDate')"
        data-cy="exportDateStart"
        prependIcon="event"
        :rules="requieredRule" />
      <singleDateForm
        v-model="value.end"
        :label="$t('incidentReports.endDate')"
        data-cy="exportDateEnd"
        prependIcon="event"
        :rules="requieredRule"
    /></v-row>
  </div>
</template>
<script>
import singleDateForm from "../../_utils/general/singleDateForm.vue";
export default {
  props: ["value"],
  components: { singleDateForm },
  data: () => ({
    requieredRule: [(v) => !!v || this.$t("general.fieldNeedsToBeFilled")],
  }),
  methods: {},
};
</script>

import axios from "axios";

const actions = {
  createIncidentReport(context, value) {
    return axios
      .post("incidentReport", value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Unfallmeldung " + r.data.name + " erfolgreich hinzugefügt"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  deleteIncidentReport(context, val) {
    return axios
      .delete("incidentReport/" + val.id)
      .then(function (res) {
        context.commit("CREATE_SNACKBAR", "Unfallmeldung erfolgreich gelöscht");
        return res;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  updateIncidentReport(context, value) {
    return axios
      .put("incidentReport/" + value.id, value)
      .then(function (r) {
        context.commit(
          "CREATE_SNACKBAR",
          "Unfallmeldung " + r.data.name + " erfolgreich verändert"
        );
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleIncidentReport(context, id) {
    return axios
      .get("incidentReport/" + id)
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  createIncidentReportPdf(context, value) {
    return axios
      .get(
        "incidentReport/pdf?start=" +
          new Date(value.start).toISOString() +
          "&end=" +
          new Date(value.end).toISOString()
      )
      .then(function (r) {
        return r.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;

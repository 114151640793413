<template>
  <div>
    <v-row class="my-3">
      <v-icon class="mr-2" disabled>attach_file</v-icon>
      <div class="subtitle-1">{{ $t("tickets.view.attachments") }}</div></v-row
    >
    <div class="ml-5">
      <div>
        <div v-for="(item, index) in attachments" v-bind:key="index">
          <v-card flat outlined class="my-2"
            ><v-card-title class="subtitle-2 py-1"
              ><v-icon class="mr-2">{{ fileIcons[item.type] }}</v-icon
              >{{ item.name }}
              <div class="flex-grow-1"></div>
              <v-btn
                color="grey"
                icon
                @click="downloadItem(item)"
                data-cy="back"
              >
                <v-icon>download</v-icon></v-btn
              ></v-card-title
            >
            <v-btn small text class="ml-1 mb-1" @click="deleteAttachment(item)"
              >{{ $t("tickets.view.deleteFile") }}</v-btn
            ></v-card
          >
        </div>
        <div class="subtitle-1 mt-5">{{ $t("tickets.view.addFile") }}</div>
        <input
          @change="handleImage"
          class="mt-2"
          type="file"
          accept="image/png, image/jpeg, image/jpg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {
      pushAttachment: {
        content: "",
        user: null,
        timestamp: null,
      },
      attachments: [],
      fileIcons: {
        zip: "mdi-folder-zip",
        rar: "mdi-folder-zip",
        htm: "mdi-language-html5",
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-json",
        md: "mdi-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        jpg: "mdi-file-image",
        JPG: "mdi-file-image",
        jpeg: "mdi-file-image",
        mp4: "mdi-filmstrip",
        mkv: "mdi-filmstrip",
        avi: "mdi-filmstrip",
        wmv: "mdi-filmstrip",
        mov: "mdi-filmstrip",
        txt: "mdi-file-document",
        xls: "mdi-file-excel",
        xlsx: "mdi-file-excel",
        other: "mdi-file-outline",
      },
    };
  },
  methods: {
    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        //post attachment
        let attachment = {
          type: fileObject.name.match(/\.[0-9a-z]+$/i)[0].split(".")[1],
          name: fileObject.name,
          content: e.target.result.split(",")[1],
          id: this.item._id,
        };
        this.$store.dispatch("addAttachmentsToTicket", attachment).then(() => {
          //reload data
          this.loadAttachments();
        });
      };
      reader.readAsDataURL(fileObject);
    },
    deleteAttachment(val) {
      this.$store
        .dispatch("removeAttachmentsfromTicket", {
          id: this.item._id,
          location: val.location,
        })
        .then(() => {
          //reload data
          this.loadAttachments();
        });
    },
    loadAttachments() {
      //set attachments
      this.$store
        .dispatch("getAttachmentsForTicket", this.item._id)
        .then((r) => {
          this.attachments = r;
        });
    },
    createFormPath(item) {
      if (item) {
        return (
          "Tickets/" + this.item._id + "/" + item.location + "." + item.type
        );
      }
    },
    async downloadItem(item) {
      //open url to download file
      window.open(await this.getDownloadItemLink(item), "_blank");
    },
    async getDownloadItemLink(item) {
      //download path
      const formPath = this.createFormPath(item);
      if (formPath) {
        return await this.$store.dispatch("getPresignedDownloadUrl", formPath);
      }
    },
  },
};
</script>

<template>
  <v-dialog v-model="data.open" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(data.title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <formComponent
            ref="formComponent"
            :form="incidentReportExportForm"
            v-model="incidentReportExportForm.formData"
          />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="grey" outlined @click="closeform">{{
          $t("general.back")
        }}</v-btn>
        <v-btn color="primary" @click="saveForm()" data-cy="saveEvent">
          {{ $t("incidentReports.createExport") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
function getDefaultData() {
  return {
    start: new Date(),
    end: new Date(),
  };
}
import formComponent from "../../components/_utils/formComponent/formComponent.vue";
import { getUniqueId } from "../../components/api/api";
export default {
  components: { formComponent },
  props: ["data"],
  data: () => ({
    incidentReportExportForm: {
      formData: getDefaultData(),
      form: [
        [
          {
            cols: 12,
            sm: 12,
            md: 12,
            class: "py-0 mt-6",
            id: getUniqueId(),
            componentName: "exportDateRange",
            config: {},
          },
        ],
      ],
    },
  }),
  methods: {
    closeform() {
      this.data.open = false;
      this.resetData();
    },
    saveForm() {
      //check if new contact form should be opened after save
      //validate form
      if (this.$refs.formComponent.validateAllFields()) {
        //create new item
        this.$store
          .dispatch(
            "createIncidentReportPdf",
            this.incidentReportExportForm.formData
          )
          .then((r) => {
            //close form
            this.closeform();
            const base64 = "data:application/pdf;base64," + r.base64;
            //open pdf
            let pdfWindow = window.open("");
            pdfWindow.document.write(
              "<head><title>" +
                "Unfallmeldung_Export" +
                "</title></head><iframe width='100%' height='100%' src='" +
                encodeURI(base64) +
                "'></iframe>"
            );
          });
      }
    },
    resetData() {
      //reset whole form data
      this.incidentReportExportForm.formData = getDefaultData();
      //reset form component form only after contact create
      this.$refs.formComponent.resetForm();
      //trigger reload data function in parent component
      this.reloadData();
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>

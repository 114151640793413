<template>
  <div>
    <tableComponent
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
      @newDocument="newDocument"
      @downloadPdf="downloadPdf"
      @clickedRow="$refs.detailsDialog.openDetails($event)"
    />
    <dangerous-good-details-dialog ref="detailsDialog" />
  </div>
</template>
<script>
import { getUniqueId } from "../../components/api/api";
import tableComponent from "../../components/_utils/dataTableHelpers/tableComponent.vue";
import DangerousGoodDetailsDialog from "@/components/tableSlotComponents/dangerousGoods/dangerousGoodsList/dangerousGoodDetailsDialog.vue";

export default {
  components: {
    tableComponent,
    DangerousGoodDetailsDialog,
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t(
            "dangerousGoods.dangerousGoodsList.tableHeader.identifier"
          ),
          value: "identifier",
        },
        {
          text: this.$t("dangerousGoods.dangerousGoodsList.tableHeader.name"),
          value: "name",
        },
        {
          text: this.$t(
            "dangerousGoods.dangerousGoodsList.tableHeader.pictograms"
          ),
          value: "dangerousGoodsListPictograms",
          width: "200px",
        },
        {
          text: this.$t(
            "dangerousGoods.dangerousGoodsList.tableHeader.hAndPSentences"
          ),
          value: "dangerousGoodsListHAndPSentences",
          width: "230px",
        },
        {
          text: this.$t(
            "dangerousGoods.dangerousGoodsList.tableHeader.storageClassification"
          ),
          value: "dangerousGoodsListStorageClassification",
        },
        {
          text: this.$t(
            "dangerousGoods.dangerousGoodsList.tableHeader.containerSize"
          ),
          value: "containerSize",
        },
        {
          text: this.$t(
            "dangerousGoods.dangerousGoodsList.tableHeader.yearlyUsage"
          ),
          value: "yearlyUsage",
          align: "center",
        },
        {
          text: this.$t(
            "dangerousGoods.dangerousGoodsList.tableHeader.usageLocation"
          ),
          value: "usageLocation",
        },
        {
          text: this.$t(
            "dangerousGoods.dangerousGoodsList.tableHeader.manufacturer"
          ),
          value: "manufacturer",
        },
        // {
        //   text: this.$t(
        //     "dangerousGoods.dangerousGoodsList.tableHeader.safetyDataSheet"
        //   ),
        //   value: "dangerousGoodsListSafetyDataSheet",
        // },
        {
          text: "",
          value: "dangerousGoodsListAction",
          align: "right",
          sortable: false,
          width: "120px",
        },
      ];
    },
    tableData() {
      return {
        tableComponentSlots: [
          {
            id: getUniqueId(),
            slotName: "item.dangerousGoodsListPictograms",
            componentName: "dangerousGoodsListPictograms",
          },
          {
            id: getUniqueId(),
            slotName: "item.dangerousGoodsListHAndPSentences",
            componentName: "dangerousGoodsListHAndPSentences",
          },
          {
            id: getUniqueId(),
            slotName: "item.dangerousGoodsListStorageClassification",
            componentName: "dangerousGoodsListStorageClassification",
          },
          {
            id: getUniqueId(),
            slotName: "item.dangerousGoodsListSafetyDataSheet",
            componentName: "dangerousGoodsListSafetyDataSheet",
          },
          {
            id: getUniqueId(),
            slotName: "item.dangerousGoodsListAction",
            componentName: "dangerousGoodsListAction",
          },
        ],
        itemKey: "_id",
        uriPartPrepend: "dangerousGood",
        uriPartAppend: `&dangerousGoodStorageLocation=${this.$route.params.storageLocationId}`,
        populate: [],
        sort: "-createdAt",
        editFormPushName: "dangerousGoodsListForm",
      };
    },
  },
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [
        // {
        //   title: "dangerousGoods.dangerousGoodsList.pdf",
        //   color: "primary",
        //   action: "downloadPdf",
        //   icon: "download",
        //   iconDark: true,
        //   dark: true,
        //   outlined: true,
        // },
        {
          title: "dangerousGoods.dangerousGoodsList.addSubstance",
          color: "secondary",
          action: "newDocument",
          icon: "add",
          iconDark: true,
          dark: true,
          outlined: true,
        },
      ],
      multipleSelectButtons: [],
      isRowClickable: true,
    },
  }),
  methods: {
    reloadDataFunc() {
      this.$refs.tableComponent.getData();
    },
    newDocument() {
      this.$router.push({
        name: this.tableData.editFormPushName,
        params: { id: "1", mode: "new", data: 1 },
      });
    },
    downloadPdf() {},
  },
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import { isAuthenticated, removeDataFromLocalStorage } from "../api.js";

//pages
import inspection from "../views/inspection/inspection.vue";
import inspectionForm from "../views/inspection/inspectionForm.vue";
import operatingInstruction from "../views/operatingInstruction/operatingInstruction.vue";
import files from "../views/files/files.vue";
import riskAssessment from "../views/riskAssessment/riskAssessment.vue";
import riskAssessmentForm from "../views/riskAssessment/riskAssessmentForm.vue";
import DangerousGoodsList from "../views/dangerousGoods/dangerousGoodsList.vue";
import DangerousGoodsListForm from "../views/dangerousGoods/dangerousGoodsListForm.vue";
import DangerousGoodsStorageLocation from "../views/dangerousGoods/dangerousGoodsStorageLocation.vue";
import DangerousGoodsStorageLocationForm from "../views/dangerousGoods/dangerousGoodsStorageLocationForm.vue";
import tickets from "../views/tickets/tickets.vue";
import instructions from "../views/instructions/instructions.vue";
import employeeGroupTable from "../views/managedInstruction/employee/employeeGroupTable.vue";
import employeeGroupForm from "../views/managedInstruction/employee/employeeGroupForm.vue";
import employeeMain from "../views/managedInstruction/employee/employeeMain.vue";
import managedInstructionAssignmentTable from "../views/managedInstruction/managedInstructionAssignmentTable.vue";
import managedInstructionAssignmentForm from "../views/managedInstruction/managedInstructionAssignmentForm.vue";
import managedInstructionsUserPage from "../views/managedInstruction/userPage.vue";
import settingsTabs from "../views/settings/settingsTabs.vue";
import boardSettings from "../views/settings/boardSettings.vue";
import emailSettings from "../views/settings/emailSettings.vue";
import multiuserSettings from "../views/settings/multiuserSettings.vue";
import profileSettings from "../views/settings/profileSettings.vue";
import consultingPage from "../views/consulting/consultingPage.vue";
import incidentReport from "../views/incidentReport/incidentReport.vue";
import incidentReportForm from "../views/incidentReport/incidentReportForm.vue";
import medkitlog from "../views/medkitlog/medkitlog.vue";
import medkitlogForm from "../views/medkitlog/medkitlogForm.vue";
import usersSettings from "../views/settings/users/usersTable.vue";
import usersForm from "../views/settings/users/usersForm.vue";
import tariffOverview from "../views/settings/tariff/tariffOverview.vue";
import upgradePayment from "../views/settings/tariff/updatePayment/updatePaymentPage.vue";
import changePlan from "../views/settings/tariff/upgradePlan/upgradeStepper.vue";

//templates
import loginTemplate from "../components/layout/loginTemplate.vue";
import registerTemplate from "../components/layout/registerTemplate.vue";
import generalView from "../components/layout/generalView.vue";
import surveyTemplate from "../views/survey/surveyTemplate.vue";
import multiuserAccept from "../views/multiuserAccept/multiuserAcceptTemplate.vue";
import managedInstruction from "@/views/managedInstruction/managedInstruction.vue";
import managedInstructionAssignmentsTable from "@/views/managedInstruction/managedInstructionAssignmentsTable.vue";
import Examination from "../views/examination/examination.vue";
import ExaminationTable from "../views/examination/examinationTable.vue";
import ExaminationForm from "../views/examination/examinationForm.vue";
import ExaminationEmployeeGroupTable from "../views/examination/employee/employeeGroupTable.vue";
import ExaminationEmployeeGroupForm from "../views/examination/employee/employeeGroupForm.vue";
import ExaminationEmployeeMain from "../views/examination/employee/employeeMain.vue";
import ExaminationAssignmentStatus from "../views/examinationAssignment/examinationAssignmentStatus.vue"
import EmployeeMain from "../views/employee/employeeMain.vue";
import EmployeeTable from "../views/employee/employeeTable.vue";
import EmployeeForm from "../views/employee/employeeForm.vue";
import EmployeeGroupTable from "../views/employee/employeeGroupTable.vue";
import EmployeeGroupForm from "../views/employee/employeeGroupForm.vue";

Vue.use(VueRouter);

/*
        path: "/",
        redirect: "/login/login/1/1",
        component: generalView,
*/

const routes = [
  {
    path: "/managedInstructionsUserPage/:managedInstructionAssignmentId/:assignmentId/:employeeId",
    name: "managedInstructionsUserPage",
    component: managedInstructionsUserPage,
    meta: {
      authNeeded: false,
    },
  },
  {
    path: "/examinationAssignment/employee/:assignmentId/:employeeId/status",
    name: "examinationAssignmentUserPage",
    component: ExaminationAssignmentStatus,
    meta: {
      authNeeded: false,
    },
  },
  {
    path: "/login/:mode/:id/:redirect/",
    name: "Login",
    component: loginTemplate,
    meta: {
      authNeeded: false,
    },
  },
  {
    path: "/survey/:mode",
    component: surveyTemplate,
    meta: {
      authNeeded: false,
    },
  },
  {
    path: "/multiuserAccept/:id/:email",
    component: multiuserAccept,
    meta: {
      authNeeded: true,
    },
  },
  {
    path: "/register/survey",
    component: registerTemplate,
    meta: {
      authNeeded: false,
    },
  },
  {
    path: "/",
    redirect: "/login/login/1/1",
    component: generalView,
    children: [
      {
        path: "/app/tickets/:mode",
        name: "tickets",
        component: tickets,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/instructions",
        name: "instructions",
        component: instructions,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/managedInstructions",
        component: managedInstruction,
        meta: {
          authNeeded: true,
        },
        children: [
          {
            path: "",
            name: "managedInstructionsTable",
            component: managedInstructionAssignmentsTable,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "assignments",
            redirect: "/app/managedInstructions",
            name: "managedInstructionsAssignments",
            component: managedInstructionAssignmentTable,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "managedInstructionAssignmentTable",
            name: "managedInstructionAssignmentTable",
            component: managedInstructionAssignmentTable,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "managedInstructionAssignmentTable/edit/:id/:mode/",
            name: "managedInstructionAssignmentForm",
            component: managedInstructionAssignmentForm,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "employee",
            redirect: "employee/employeeTable",
            name: "managedInstructionEmployeeMain",
            component: employeeMain,
            meta: {
              authNeeded: true,
            },
            children: [
              {
                path: "employeeTable",
                name: "managedInstructionEmployeeGroupTable",
                component: employeeGroupTable,
                meta: {
                  authNeeded: true,
                },
              },
              {
                path: "employeeTable/edit/:id/:mode/",
                name: "managedInstructionEmployeeGroupForm",
                component: employeeGroupForm,
                meta: {
                  authNeeded: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: "/app/examination",
        component: Examination,
        meta: {
          authNeeded: true,
        },
        children: [
          {
            path: "",
            name: "examinationTable",
            component: ExaminationTable,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "examinationTable/edit/:id/:mode/",
            name: "examinationForm",
            component: ExaminationForm,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "employee",
            redirect: "employee/employeeTable",
            component: ExaminationEmployeeMain,
            meta: {
              authNeeded: true,
            },
            children: [
              {
                path: "employeeTable",
                name: "examinationEmployeeGroupTable",
                component: ExaminationEmployeeGroupTable,
                meta: {
                  authNeeded: true,
                },
              },
              {
                path: "employeeTable/edit/:id/:mode/",
                name: "examinationEmployeeGroupForm",
                component: ExaminationEmployeeGroupForm,
                meta: {
                  authNeeded: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: "/app/employee",
        redirect: "/app/employee/employeeTable",
        component: EmployeeMain,
        meta: {
          authNeeded: true,
        },
        children: [
          {
            path: "employeeTable",
            component: EmployeeTable,
            name: "employeeTable",
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "employeeTable/edit/:id/:mode/",
            name: "employeeForm",
            component: EmployeeForm,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "employeeGroupTable",
            name: "employeeGroupTable",
            component: EmployeeGroupTable,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "employeeGroupTable/edit/:id/:mode/",
            name: "employeeGroupForm",
            component: EmployeeGroupForm,
            meta: {
              authNeeded: true,
            },
          },
        ],
      },
      {
        path: "/app/riskAssessment",
        name: "riskAssessment",
        component: riskAssessment,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/riskAssessment/edit/:id/:mode/",
        props: true,
        name: "riskAssessmentForm",
        component: riskAssessmentForm,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/dangerousGoods",
        name: "dangerousGoods",
        component: DangerousGoodsStorageLocation,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/dangerousGoods/edit/:id/:mode/",
        props: true,
        name: "dangerousGoodsStorageLocationForm",
        component: DangerousGoodsStorageLocationForm,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/dangerousGoods/:storageLocationId/",
        redirect: "/app/dangerousGoods/",
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/dangerousGoods/:storageLocationId/:storageLocationName",
        props: true,
        name: "dangerousGoodsList",
        component: DangerousGoodsList,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/dangerousGoods/:storageLocationId/:storageLocationName/edit/:id/:mode/",
        props: true,
        name: "dangerousGoodsListForm",
        component: DangerousGoodsListForm,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/operatingInstructions",
        name: "operatingInstruction",
        component: operatingInstruction,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/inspections",
        name: "inspections",
        component: inspection,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/inspections/edit/:id/:mode/",
        props: true,
        name: "inspectionForm",
        component: inspectionForm,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/incidentReports",
        name: "incidentReport",
        component: incidentReport,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/incidentReports/edit/:id/:mode/",
        props: true,
        name: "incidentReportForm",
        component: incidentReportForm,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/medkitlogs",
        name: "medkitlog",
        component: medkitlog,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/medkitlogs/edit/:id/:mode/",
        props: true,
        name: "medkitlogForm",
        component: medkitlogForm,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/files",
        name: "files",
        component: files,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/consulting",
        name: "consulting",
        component: consultingPage,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/upgradePayment",
        name: "upgradePayment",
        component: upgradePayment,
        meta: {
          authNeeded: true,
        },
      },
      {
        path: "/app/settings",
        redirect: "/app/settings/boards",
        component: settingsTabs,
        meta: {
          authNeeded: true,
        },
        children: [
          {
            path: "profile",
            name: "profileSettings",
            component: profileSettings,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "boards",
            name: "boardSettings",
            component: boardSettings,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "email",
            name: "emailSettings",
            component: emailSettings,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "multiuser",
            name: "multiuserSettings",
            component: multiuserSettings,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "tariff",
            name: "tariffOverview",
            component: tariffOverview,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "users",
            name: "usersSettings",
            component: usersSettings,
            meta: {
              authNeeded: true,
            },
          },
          {
            path: "usersSettings/edit/:id/:mode/",
            props: true,
            name: "usersForm",
            component: usersForm,
            meta: {
              authNeeded: true,
            },
          },
        ],
      },
      {
        path: "/app/changePlan/:step/:portalData/:selected",
        props: true,
        name: "changePlan",
        component: changePlan,
        meta: {
          authNeeded: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//navigation guard
router.beforeEach((to, from, next) => {
  //check if auth is needed
  if (to.meta.authNeeded) {
    //needed
    isAuthenticated().then((r) => {
      if (!r) {
        //remove token from localstorage
        removeDataFromLocalStorage();
        next({
          name: "Login",
          params: {
            mode: "login",
            id: 1,
            redirect: encodeURIComponent(
              window.location.origin + window.location.pathname
            ),
          },
        });
        next();
      } else {
        next();
      }
    });
  } else {
    //not needed
    next();
  }
});

export default router;

<template>
  <div>
    <tableComponent
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
      @newDocument="newDocument"
      @reloadData="reloadDataFunc"
    />
  </div>
</template>
<script>
import { getUniqueId } from "../../components/api/api";
import tableComponent from "../../components/_utils/dataTableHelpers/tableComponent.vue";

export default {
  components: {
    tableComponent,
  },
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [
        {
          title: "inspections.addInspection",
          color: "secondary",
          action: "newDocument",
          icon: "add",
          iconDark: true,
          dark: true,
          outlined: true,
        },
      ],
      multipleSelectButtons: [],
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.formattedInspectionStatus",
          componentName: "formattedInspectionStatus",
        },
        {
          id: getUniqueId(),
          slotName: "item.formattedInspectionDate",
          componentName: "formattedInspectionDate",
        },
        {
          id: getUniqueId(),
          slotName: "item.inspectionFormattedCreatedAt",
          componentName: "inspectionFormattedCreatedAt",
        },
        {
          id: getUniqueId(),
          slotName: "item.inspectionAction",
          componentName: "inspectionAction",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "inspection",
      uriPartAppend: "",
      populate: [],
      sort: "-createdAt",
      editFormPushName: "inspectionForm",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("inspections.tableHeader.status"),
          value: "formattedInspectionStatus",
          align: "left",
        },
        { text: this.$t("inspections.tableHeader.inspection"), value: "title" },
        {
          text: this.$t("inspections.tableHeader.inspectionDate"),
          value: "formattedInspectionDate",
        },
        {
          text: this.$t("inspections.tableHeader.topicCount"),
          value: "areas.length",
        },
        {
          text: this.$t("inspections.tableHeader.createdAt"),
          value: "inspectionFormattedCreatedAt",
        },
        {
          text: "",
          value: "inspectionAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    reloadDataFunc() {
      this.$refs.tableComponent.getData();
    },
    newDocument() {
      this.$router.push({
        name: this.tableData.editFormPushName,
        params: { id: "1", mode: "new", data: 1 },
      });
    },
    deleteItem(item) {
      //trigger multiple select delete
      this.$store.dispatch("deleteMultipleInspection", item).then(() => {
        this.reloadDataFunc();
        this.multipleDeleteDialog.open = false;
        this.multipleDeleteDialog.items = [];
      });
    },
  },
};
</script>

<template>
  <div>
    <multiuserAcceptAppBar />
    <v-row align="start" class="my-16">
      <v-col cols="12" md="2" sm="2"> </v-col>
      <v-col cols="12" md="8" sm="8">
        <h3>{{ multiuserState }}</h3>
        </br>
        </br>
        <v-btn
          block
          color="primary"
          @click="redirectToLogin"
          class="mt-2"
          >Jetzt anmelden...
        </v-btn>
      </v-col>
      <v-col cols="12" md="2" sm="2"> </v-col>
    </v-row>
  </div>
</template>
<script>
//import localforage from "localforage";
import multiuserAcceptAppBar from "./multiuserAcceptAppBar.vue";
export default {
  components: {
    multiuserAcceptAppBar,
  },
  data() {
    return {
      multiuserState: "Multiuser (FASI) wird hinzugefügt...",
    };
  },
  mounted() {
    if (this.$route.params.id != "" && this.$route.params.email != "") {
      this.$store
        .dispatch("multiuserCodeRedeem", {
          code: this.$route.params.id,
          email: this.$route.params.email,
        })
        .then((r) => {
          console.log(r.response.data.message);
          if (true) {
            this.multiuserState = "Nutzer erfolgreich hinzugefügt.";
          }
        });
    }
  },
  methods: {
    redirectToLogin() {
      this.$router.push("/login/login/1/1");
    },
  },
};
</script>

<template>
  <div>
    <tableComponent
      class="pa-5 pl-0"
      ref="tableComponent"
      :tableSettings="tableSettings"
      :tableData="tableData"
      :headers="headers"
      :enable-row-clickable="true"
      @newDocument="newDocument"
      @import="openImport"
    />
    <importDialog @triggerUpload="importData" :importDialog="importDialog" />
  </div>
</template>
<script>
import { getUniqueId } from "@/components/api/api";
import tableComponent from "@/components/_utils/dataTableHelpers/tableComponent.vue";
import importDialog from "./importDialog.vue";
export default {
  components: {
    tableComponent,
    importDialog,
  },
  data: () => ({
    tableSettings: {
      title: "",
      showSelect: false,
      showHeaderToolbar: true,
      headerButtons: [
        {
          title: "employee.importEmployee",
          color: "grey",
          action: "import",
          icon: "download",
          iconDark: true,
          dark: true,
          outlined: true,
        },
        {
          title: "employee.addEmployee",
          color: "secondary",
          action: "newDocument",
          icon: "add",
          iconDark: true,
          dark: true,
          outlined: true,
        },
      ],
      multipleSelectButtons: [],
    },
    tableData: {
      tableComponentSlots: [
        {
          id: getUniqueId(),
          slotName: "item.employeeFormattedCreatedAt",
          componentName: "employeeFormattedCreatedAt",
        },
        {
          id: getUniqueId(),
          slotName: "item.employeeAction",
          componentName: "employeeAction",
        },
      ],
      itemKey: "_id",
      uriPartPrepend: "employee",
      uriPartAppend: "",
      populate: [],
      sort: "",
      editFormPushName: "employeeForm",
    },
    importDialog: {
      open: false,
      title: "employee.importEmployeeTitle",
      description: "",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("employee.tableHeader.name"),
          value: "name",
          align: "left",
        },
        { text: this.$t("employee.tableHeader.email"), value: "email" },
        {
          text: this.$t("employee.tableHeader.language"),
          value: "employeeLanguage",
        },
        {
          text: this.$t("employee.tableHeader.createdAt"),
          value: "employeeFormattedCreatedAt",
        },
        {
          text: "",
          value: "employeeAction",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    reloadDataFunc() {
      this.$refs.tableComponent.getData();
    },
    newDocument() {
      this.$router.push({
        name: this.tableData.editFormPushName,
        params: { id: "1", mode: "new", data: 1 },
      });
    },
    deleteItem(item) {
      //trigger multiple select delete
      this.$store.dispatch("deleteMultipleOperatingemployee", item).then(() => {
        this.reloadDataFunc();
        this.multipleDeleteDialog.open = false;
        this.multipleDeleteDialog.items = [];
      });
    },
    openImport() {
      this.importDialog.open = true;
    },
    importData(item) {
      this.$store
        .dispatch("createEmployeeImport", { file: item.content })
        .then(() => {
          this.reloadDataFunc();
          this.importDialog.open = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <managed-instruction-assignment-overdue-send-reminder
      v-if="!isCompleted(item)"
      v-bind:employee="item?.assignments?.employee"
      v-bind:instruction-id="item?._id"
    />
    <v-tooltip v-else right>
      <template v-slot:activator="{ attrs, on }">
        <v-icon color="success" v-bind="attrs" v-on="on">check</v-icon>
      </template>

      {{
        $t(
          "managedInstructions.assignedDialog.tableSlotComponents.action.completedInstruction"
        )
      }}
    </v-tooltip>
  </div>
</template>
<script>
import ManagedInstructionAssignmentOverdueSendReminder from "@/views/managedInstruction/managedInstructionAssignmentOverdueSendReminder.vue";
export default {
  components: { ManagedInstructionAssignmentOverdueSendReminder },
  props: ["item"],
  methods: {
    isCompleted(item) {
      return item?.assignments?.completedAt ?? false;
    },
  },
};
</script>

<template>
  <v-dialog v-model="deleteDialog.open" max-width="500" persistent>
    <v-card>
      <v-card-title class="headline">{{ $t(deleteDialog.title) }}</v-card-title>
      <v-card-text><b>{{ deleteDialog.description }}</b></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="deleteDialog.open = false"
          >{{ $t("general.cancel") }}</v-btn
        >
        <v-btn
          color="error"
          text
          @click="$emit('triggerDelete', item)"
          data-cy="deleteEvent"
          >{{ $t("general.deleteNow") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["deleteDialog", "item"],
};
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <div class="subtitle-1 mt-5">
          {{ $t("inspections.addInspectionProof") }}
        </div>
        <input
          @change="handleImage"
          class="mt-2"
          type="file"
          accept="application/pdf" /></v-col
    ></v-row>
  </div>
</template>
<script>
export default {
  props: ["value"],
  methods: {
    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.value.proof = {};
        this.value.proof.filetype = fileObject.name
          .match(/\.[0-9a-z]+$/i)[0]
          .split(".")[1];
        this.value.proof.name = fileObject.name;
        this.value.proof.content = e.target.result.split(",")[1];
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>

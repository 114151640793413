<template>
  <div>
    <div v-if="item.backToWork">{{ $t("incidentReports.yes") }}</div>
    <div v-else>{{ $t("incidentReports.no") }}</div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  methods: {},
};
</script>

<template>
  <div>
    {{ item.name }}
  </div>
</template>
<script>
export default {
  props: ["item"],
};
</script>
